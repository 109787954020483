class LogoCarousel {
	constructor() {
		this.blocks = document.querySelectorAll('.block-logo-carousel');
		this.init();
	}

	init() {
		this.blocks.forEach((block) => {
			const swiper = new Swiper('.block-logo-carousel .swiper', {
				slidesPerView: 3,
				spaceBetween: 30,
				// centeredSlides: true,
				loop: true,
				autoplay: {
					delay: 0,
				},
				speed: 3000,
				breakpoints: {
					768: {
						slidesPerView: 4,
						spaceBetween: 30,
					},
					992: {
						slidesPerView: 6,
						spaceBetween: 30,
					}
				}
			});


			const centerSlides = () => {
				const swiperSlides = block.querySelectorAll('.swiper-slide').length;
				const swiperWrap = block.querySelector(".swiper-wrapper");

				if (window.innerWidth >= 768) {
					if (swiperSlides < 4) {
						swiperWrap.style.justifyContent = "center";
					} else {
						swiperWrap.style.justifyContent = "flex-start";
					}
				} else if (window.innerWidth >= 992) {
					if (swiperSlides < 6) {
						swiperWrap.style.justifyContent = "center";
					} else {
						swiperWrap.style.justifyContent = "flex-start";
					}
				} else {
					if (swiperSlides < 3) {
						swiperWrap.style.justifyContent = "center";
					} else {
						swiperWrap.style.justifyContent = "flex-start";
					}
				}

			}

			centerSlides();

			window.addEventListener('resize', function(event) {
				centerSlides();
			}, true);



		});


	}
}

new LogoCarousel();
